.insights-container{
    width: 100vw;
    height: 100vh;
}

.insights-content{
    position: relative;
    top: 50%;
    left: 50%;
    transform:translate(-50%, -50%);
    width: 80%;
}

.insights-card{
    background-color:white;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 5px 0 rgba(0,0,0,.1);
}