.privacy-policy-container {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    text-align: left;
    /* margin-top: 50px;
    margin-bottom: 50px; */
  }

  .privacy-policy-content{
    width: 75%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    text-transform:none;
    margin-top: 100px;
  }
  
  .privacy-policy-header {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .privacy-policy-subheader {
    font-size: 24px;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  
  .privacy-policy-text {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
  }
  
  .privacy-policy-list {
    list-style: disc;
    text-align: left;
    margin-left: 30px;
    margin-bottom: 20px;
  }
  
  .privacy-policy-list li {
    font-size: 16px;
    line-height: 1.5;
  }
  
  @media screen and (max-width: 768px) {
    .privacy-policy-container {
      /* margin-top: 30px; */
      margin-bottom: 30px;
    }
    
    .privacy-policy-header {
      font-size: 24px;
      margin-bottom: 10px;
    }
  
    .privacy-policy-subheader {
      font-size: 20px;
      margin-top: 20px;
      margin-bottom: 5px;
    }
  
    .privacy-policy-text {
      font-size: 14px;
      margin-bottom: 10px;
    }
  
    .privacy-policy-list {
      margin-left: 20px;
      margin-bottom: 10px;
    }
  
    .privacy-policy-list li {
      font-size: 14px;
    }
  }