.header{
    position:fixed;
    z-index:1;
    width: 100vw;
    height: 34px;
    background-color: #f6f4ee;
    padding-bottom: 15px;
    transition: all .4s ease-in-out;
    z-index: 2;
}

.logo{
    width: 40px;
    position: absolute;
    left: 10px;
    top: 5px
}

.header-container-m{
    width: 100vw;
    height: 100vh;
    background-color: #f6f4ee;
    position: fixed;
    border-bottom: 1px solid black;
    z-index: 1;
}

.inactive{
    transform:translateY(-100%);
    transition: all 500ms ease-in-out;
    pointer-events: none;
}


.show{
    transform:translateY(0%);
    opacity: 1;
    transition: all 500ms ease-in-out;
}

#menu-icon{
    width: 30px;
    position: absolute;
    top:18px;
    right:15px;
    z-index:9;
}

.active{
    box-shadow: 0 0 15px rgba(0, 0, 0, 1);
}

.menu-content{
    position: fixed;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
}

ul{
    list-style-type: none;
    text-align: center;
}

.menu-content li{
    margin-bottom: 10px;
}

.menu-content li a{
    text-decoration: none;
    font-family: "custom-body";
    /* letter-spacing: 1px; */
    font-size: 20px;
    list-style: none;
    text-transform:none;
    /* text-transform: uppercase; */
    transition: all .4s ease-in;
    cursor: pointer;
}


.header h1{
    font-family: "custom-body";
    letter-spacing: 1px;
    left: 20px;
    position: relative;
    top: 10px;
    font-size: 20px;
}

.new-font{
    font-family: "custom-body";
    letter-spacing: 1px;
    position: relative;
    font-size: 20px;
    text-decoration: none;
}

.header p{
    font-family:"Custom";
    font-size: 13px;
    line-height: 15px;
    left:10px; 
    text-decoration: uppercase;
    position: relative;
}
