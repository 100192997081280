.charge-list-container {
    margin: 0 auto;
    max-width: 600px;
  }
  
  .charge-list-table {
    border-collapse: collapse;
    margin-bottom: 20px;
    width: 100%;
  }
  
  .charge-list-table th,
  .charge-list-table td {
    border: 1px solid #eaeaea;
    padding: 12px;
  }
  
  .charge-list-table th {
    background-color: #f7f7f7;
    font-size: 14px;
    font-weight: 600;
    text-align: left;
  }
  
  .charge-list-table td {
    font-size: 14px;
    vertical-align: middle;
  }
  
  .charge-list-table td:nth-child(3) {
    text-align: right;
  }
  
  .pagination-container {
    display: flex;
    justify-content: center;
  }
  
  .pagination-button {
    background-color: #f7f7f7;
    border: 1px solid #eaeaea;
    color: #333;
    font-size: 14px;
    font-weight: 600;
    margin-right: 5px;
    padding: 12px;
    transition: background-color 0.2s ease;
  }
  
  .pagination-button:hover {
    background-color: #eaeaea;
  }
  
  .pagination-button:disabled {
    opacity: 0.5;
  }
  
  .pagination-button.active {
    background-color: #333;
    color: #fff;
  }

  .charge-list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .charge {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
    margin-bottom: 1rem;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
    border-radius: 5px;
  }
  
  .charge .charge-date {
    font-size: 0.8rem;
    color: #aaa;
  }
  
  .charge .charge-amount {
    font-size: 1.2rem;
    font-weight: bold;
  }

  .amount-text{
    font-family:"custom-body";
    font-size: 24px;
  }

  .date-text{
    font-family:"custom-body";
    font-size: 12px;
  }