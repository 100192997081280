.event-container{
    width: 100vw;
    height: 100vh;
}

.event-content{
    position:relative;
    top: 50%;
    left:50%;
    width: 300px;
    transform: translate(-50%,-50%);
}