.register-container{
    width: 100vw;
    height: 100vh;
}

.register-content{
    width: 300px;
    position: relative;
    left: 50%;
    top: 50%;
    transform:translate(-50%,-50%);
}

.login-button-google{
    width: 100%;
    background-color:white;
    border: 1px solid black;
    padding: 10px;
    margin-bottom: 15px;
}

.register-prompt{
    font-size: 12px !important;
    text-transform: uppercase;
}

.header-text{
    text-align: center;
    margin-bottom: 10px;
    font-family:'custom-body';
    font-size: 20px;
    text-transform: none;
}
