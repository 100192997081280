.dashboard-restaurant-container{
    width: 100vw;
    height: 100vh;
}

.dashboard-restaurant-content{
    width: 300px;
    height: auto;
    position: relative;
    left:50%;
    transform: translate(-50%, -50%);
    top: 50%;
    background-color: white;
    padding: 40px;
    border-radius: 50px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
}

.welcome-restaurant-content{
    /* margin-bottom: 20px; */
    text-align: center;
}


.dashboard-button{
    width: 300px;
    border: 1px solid black;
    background-color: white;
    height: 30px;
    margin-top: 15px;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;
    margin-bottom: 20px;
    /* transition: all .4s ease-in; */

}

/* .dashboard-button:hover{
    color:white;
    background-color: black;
} */

.dashboard-login-btn{
    /* margin-bottom: 20px; */
}

.create-payment-content{
    text-align: center;
}

.input-form{
    width: 294px;
    border: 1px solid black;
    height: 34px;
    font-size: 12px;
    text-decoration: uppercase;
    outline:0;
    /* letter-spacing: 1px; */
}

#create-payment-form-amount{
    margin-top: 15px;
    margin-bottom: 10px;
}

.no-margin-bottom{
    margin-bottom: 0px !important;
}

.selected:active{
    /* border-color: blue; */
    background-color: black;
    color: white;
}

.no-margin-top{
    margin-top: 0px;
}

.margin-top{
    margin-top: 10px;
}

.welcome-restaurant-content h1, p{
    font-family: "custom-body";
}

.welcome-restaurant-content p{
    margin-bottom: 25px;
}

@media only screen and (max-width: 767px) {
	.dashboard-restaurant-content{
        width: 250px;
	}
  }