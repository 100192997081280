.payment-container{
    width: 100vw;
    height: 100vh;
}


.payment-content{
    position: relative;
    top: 100px;
}


.create-payment-content{
    /* float: left; */
    /* margin-left: 20px; */
    width: 100%;
    z-index: 99;
}

.create-payment-content h1{
    font-family: 'custom';
    font-size: 25px;
    /* text-align: left;
    margin-left: 20px;
    margin-bottom: 20px; */
}

.items-container{
    margin: 0px auto;
    /* margin-left: 20px; */
    width:300px;
    height:200px;
    /* background-color:#fff; */
    display:grid;
    grid-template-columns: 300px;
    grid-row: auto auto;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
}

.items-container .item{
    padding:20px;
    border-radius:10px;
    color:#000;
    background-color: #f6f4ee;
    border: 1px solid black;
    display:flex;
    align-items:center;
    justify-content:center;
    font-family: 'custom-body';
    font-size: 20px;
    text-transform:none;
    /* font-size:40px;
    font-family:sans-serif; */
}

.cart-content-container{
    position: relative;
    width: 100%;
    margin-top: 50px;
}

.cart-content-container h1{
    font-family: 'custom';
    font-size: 25px;
    text-align: center;
    margin-bottom: 10px;
}

.border-above{
    border-top: 1px solid black;
    width: 300px;
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.cart-content{
    margin-right: 15px;
    width: 50%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.cart-content h1{
    font-family: 'custom-body';
    font-size: 25px;
    text-align: center;
}

.cart-content p{
    font-family: 'custom-body';
    font-size: 20px;
    margin-bottom: 5px;
    text-align: center;
    text-transform: none;
}


.bigger-text{
    /* font-size: 25px; */
    /* border: 1px solid black;
    padding: 10px;
    width: 150px;
    position: relative;
    left: 50%;
    transform: translateX(-50%); */
}

.payment{
    display: block;
    position: relative;
    left:50%;
    transform:translateX(-50%);
    width: 300px;
    /* z-index:-1; */
}

.payment-button{
    font-family: 'custom';
    font-size: 20px;
    width: 100%;
    background-color: white;
    border: 1px solid black;
    text-transform: uppercase;
}

.success-payment{
    font-family: 'custom-body';
    font-size: 15px;
    text-align: center;
    margin-top: 10px;
}


@media only screen and (max-width: 600px) {
    .cart-content {
        width: 75%;
    }
  }


#accept-coat-number{
    position: relative;
    left: 50%;
    transform:translateX(-50%);
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: transparent;
    border: 1px solid black;
    /* border-bottom: 1px solid black; */
}